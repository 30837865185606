#addJobs,
#viewMyprofile {
  position: relative;
  margin-top: 10px;
  top: 120px;
  left: 5px;
}
.hiddens {
  display: none;
}

.addingJobs,
.viewAddedJobs {
  display: none;
}

.shows {
  display: block;
}

#delete-job {
  position: relative;
  z-index: 100;
}

.addingJobs {
  width: 400px;
  margin: 150px auto;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  opacity: 0.9;
}

.jobAddition {
  position: absolute;
  height: 100vh;
  width: 100vw;
  background-repeat: no-repeat;
  background-size: cover;
}

.deleteJob {
  position: relative;
  margin-left: 10px;
  margin-right: 10px;
  padding-top: 2px;
  cursor: pointer;
}

.modal-overlay .modal-content #close-modal {
  position: absolute;
  cursor: pointer;
  margin: 2px 2px;

  border-radius: 8px;
  top: 10px;
  right: 10px;
}

#Light .deleteJob {
  background-color: white;
  color: #848884;
}

#Dark .deleteJob {
  background-color: #1d2226;
  color: #848884;
  border: 2px solid #848884;
}

.modal-delete-overlay.show {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s linear 0s, opacity 0.3s linear;
}
.modal-delete-overlay.hide {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 0.3s, opacity 0;
}

.modal-delete-overlay .modal-delete-content #close-modal {
  position: absolute;
  cursor: pointer;
  margin: 2px 2px;

  border-radius: 8px;
  top: 10px;
  right: 10px;
}

.addjob-container {
  width: 400px;
  margin: 120px auto;
  padding: 20px;
  background-color: #73a6e0;
  border-radius: 10px;
  text-align: center;
  opacity: 0.9;
}

#confirm-delete-modal {
  align-items: center;
  right: 0px;
}

@media only screen and (max-width: 500px) {
  .addjob-container {
    width: 300px;
    margin: 130px auto;
  }
}

@media only screen and (max-height: 500px) {
  .addjob-container {
    margin: 110px auto;
  }
}
